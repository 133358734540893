<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import {paginationHelper} from "@/helpers/pagination-helper";
import {errorCatcher} from "@/helpers/error-catcher";
import axios from "axios";
import {jsonUtil} from "@/helpers/json-util";
import EcatLightboxGallery from "@/components/ecat-lightbox-gallery.vue";
import {useUserStore} from "@/store/user";
import {computed} from "vue";
import Swal from "sweetalert2";

/**
 * AI Products Generate Component
 */
export default {

  components: {
    EcatLightboxGallery,
    Layout,
    PageHeader
  },

  setup() {
    const userStore = useUserStore();
    return {
      user: computed(() => userStore.getUser)
    }
  },

  computed: {
    jsonUtil() {
      return jsonUtil
    },

    paginationHelper() {
      return paginationHelper
    }
  },

  data() {
    return {
      submitted: false,

      table: {
        rows: 0,
        totalRows: 0,
        currentPage: 1,
        perPage: 10,
        pageOptions: [5, 10, 25, 50, 100],
        filter: null,
        filterOn: [],
        sortBy: "name",
        sortDesc: false,
        isBusy: false,
        elements: []
      },

      selectedProductImage: {
        visible: false,
        images: []
      },

      modals: {
        details: {
          visible: false,
          form: {
            element: null
          }
        },

        accepting: {
          visible: false,
          form: {
            shopId: "",
            type: "",
            value: ""
          }
        }
      }
    }
  },

  methods: {
    getItems() {
      return [
        {
          text: "ecat"
        },
        {
          text: this.$t('ai-products-generate.title'),
          active: true
        }
      ]
    },

    getFields() {
      return [
        { key: "image", label: "Zdjęcie"},
        { key: "name", label: "Oryginalna nazwa"},
        { key: "generatedName", label: "Wygenerowano nazwę"},
        { key: "generatedDescription", label: "Wygenerowano opis"},
        { key: "action", label: this.$t('table.actions') }
      ]
    },

    async loadProducts() {
      try {
        const json = JSON.stringify({
          "ids": this.$store.getters["aiproductsgenerate/getProductIds"]
        });

        const { data } = await axios.post(`/product/list-by-ids`, json, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {},
        });

        this.table.rows = data.length
        this.table.totalRows = data.length

        const products = []
        for (const product of data) {
          products.push({
            product: product,
            aiName: {
              generating: false,
              generated: null,
              error: null,
              rejected: false,
              accepted: false
            },

            aiDescription: {
              generating: false,
              generated: null,
              error: null,
              rejected: false,
              accepted: false
            }
          })
        }

        this.table.elements = products
        this.fillGenerates()
        await this.generateAll()
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    selectProductImages(images) {
      this.selectedProductImage.images = images
      this.selectedProductImage.visible = true
    },

    hideSelectedProductImage() {
      this.selectedProductImage.images = []
      this.selectedProductImage.visible = false
    },

    async generateAll() {
      await this.generateNames()
      await this.generateDescriptions()
    },

    fillGenerates() {
      if (!this.$store.getters["aiproductsgenerate/shouldGenerateName"]) {
        for (const element of this.table.elements) {
          element.aiName.error = " "
        }
      }

      if (!this.$store.getters["aiproductsgenerate/shouldGenerateDescription"]) {
        for (const element of this.table.elements) {
          element.aiDescription.error = " "
        }
      }
    },

    async generateNames() {
      if (!this.$store.getters["aiproductsgenerate/shouldGenerateName"]) {
        return
      }

      for (const element of this.table.elements) {
        await this.generateName(element)
      }
    },

    async generateDescriptions() {
      if (!this.$store.getters["aiproductsgenerate/shouldGenerateDescription"]) {
        return
      }

      for (const element of this.table.elements) {
        await this.generateDescription(element)
      }
    },

    async generateName(element) {
      element.aiName.generating = true

      try {
        const {data} = await axios.get(`/product/ai/generate/name/${element.product.id}`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {}
        });

        element.aiName.generating = false
        element.aiName.generated = data
      } catch (error) {
        element.aiName.generating = false
        element.aiName.error = errorCatcher.catchErrors(error)
      }
    },

    async generateDescription(element) {
      element.aiDescription.generating = true

      try {
        const {data} = await axios.get(`/product/ai/generate/description/${element.product.id}`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {}
        });

        element.aiDescription.generating = false
        element.aiDescription.generated = data
      } catch (error) {
        element.aiDescription.generating = false
        element.aiDescription.error = errorCatcher.catchErrors(error)
      }
    },

    openDetailsModal(element) {
      this.modals.details.form.element = element
      this.modals.details.visible = true
    },

    hideDetailsModal() {
      this.modals.details.visible = false
      this.modals.details.form.element = null
    },

    openAcceptingModal(type, value) {
      this.modals.accepting.visible = true
      this.modals.accepting.form.type = type
      this.modals.accepting.form.value = value
    },

    hideAcceptingModal() {
      this.modals.accepting.form.shopId = ""
      this.modals.accepting.form.type = null
      this.modals.accepting.form.value = null
      this.modals.accepting.visible = false
    },

    acceptGenerated() {
      this.submitted = true
      const json = JSON.stringify({
        "productId": this.modals.details.form.element.product.id,
        "shopId": this.modals.accepting.form.shopId ? this.modals.accepting.form.shopId : null,
        "type": this.modals.accepting.form.type,
        "value": this.modals.accepting.form.value
      });

      axios.put(`/product/custom-details`, json, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      }).then(() => {
        if (this.modals.accepting.form.type === "NAME") {
          this.modals.details.form.element.aiName.accepted = true
        } else {
          this.modals.details.form.element.aiDescription.accepted = true
        }

        Swal.fire("Sukces!", "Pomyślnie zaakceptowano", "success")
            .then(() => this.hideAcceptingModal());

        this.submitted = false
      }).catch((error) => {
        errorCatcher.catchErrors(error)
        this.submitted = false;
      })
    }

  },

  async created() {
    await this.loadProducts()
  }

};
</script>

<template>
  <Layout>
    <PageHeader :title="$t('ai-products-generate.title')" :items="getItems()" />
    <ecat-lightbox-gallery :visible="selectedProductImage.visible" :images="selectedProductImage.images" @hide="hideSelectedProductImage" />

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="py-4">
              <div class="row py-3">
                <div class="col">
                  <div class="dataTables_paginate paging_simple_numbers float-right">
                    <ul class="pagination pagination-rounded mb-0">
                      <b-pagination v-model="table.currentPage" :total-rows="table.rows" :per-page="table.perPage" />
                    </ul>
                  </div>
                </div>
              </div>

              <div class="table-responsive">
                <b-table

                    ref="table"
                    :items="table.elements"
                    :fields="getFields()"
                    responsive="sm"
                    :per-page="table.perPage"
                    :current-page="table.currentPage"
                    :sort-by.sync="table.sortBy"
                    :sort-desc.sync="table.sortDesc"

                    :filter-included-fields="table.filterOn"
                    :empty-text="$t('message.no-elements')"
                    :empty-filtered-text="$t('message.no-records')"
                    :show-empty="true"
                    :busy.sync="table.isBusy">
                  <div slot="table-busy" class="text-center">
                    <h5>{{ $t('message.loading') }}</h5>
                    <span aria-hidden="true" class="m-2 spinner-border text-primary" />
                  </div>

                  <template v-slot:cell(image)="{ item }">
                    <div :set="images = jsonUtil.asArray(item.product.images)">
                      <template v-if="images.length > 0">
                        <img :src="images[0]" alt="Product Image" class="rounded avatar-lg"  @click="selectProductImages(jsonUtil.asArray(item.product.images))">
                      </template>
                    </div>
                  </template>

                  <template v-slot:cell(name)="{ item }">
                    <span>{{ item.product.name }}</span>
                  </template>

                  <template v-slot:cell(generatedName)="{ item }">
                    <template v-if="item.aiName.generated">
                      <span aria-hidden="true" class="m-2 fa fa-check text-success" />
                      <span class="text-danger" v-if="item.aiName.rejected">(odrzucono)</span>
                      <span class="text-success" v-if="item.aiName.accepted">(zaakceptowano)</span>
                    </template>
                    <template v-else-if="item.aiName.error">
                      <span aria-hidden="true" class="m-2 fa fa-times text-danger" />

                      <span class="text-danger">{{ item.aiName.error }}</span>
                    </template>
                    <template v-else>
                      <span aria-hidden="true" class="m-2 spinner-grow text-primary" />
                    </template>
                  </template>

                  <template v-slot:cell(generatedDescription)="{ item }">
                    <template v-if="item.aiDescription.generated">
                      <span aria-hidden="true" class="m-2 fa fa-check text-success" />
                      <span class="text-danger" v-if="item.aiDescription.rejected">(odrzucono)</span>
                      <span class="text-success" v-if="item.aiDescription.accepted">(zaakceptowano)</span>
                    </template>
                    <template v-else-if="item.aiDescription.error">
                      <span aria-hidden="true" class="m-2 fa fa-times text-danger" />

                      <span class="text-danger">{{ item.aiDescription.error }}</span>
                    </template>
                    <template v-else>
                      <span aria-hidden="true" class="m-2 spinner-grow text-primary" />
                    </template>
                  </template>

                  <template v-slot:cell(action)="{ item }">
                    <div class="button-items">
                      <b-button @click="openDetailsModal(item)" variant="primary" class="btn-sm" :disabled="!item.aiName.generated && !item.aiDescription.generated">Szczegóły</b-button>
                    </div>
                  </template>
                </b-table>
              </div>

              <div class="row">
                <div class="col">
                  <div class="float-left">
                    <p>{{ $t('table.entries-footer', { 'amount': paginationHelper.getElementsAmount(table), 'elements': paginationHelper.getElementsCount(table), 'all': table.totalRows }) }}</p>
                  </div>

                  <div class="dataTables_paginate paging_simple_numbers float-right">
                    <ul class="pagination pagination-rounded mb-0">
                      <b-pagination v-model="table.currentPage" :total-rows="table.rows" :per-page="table.perPage" />
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal
        id="modal-1"
        size="xl"
        v-model="modals.details.visible"
        v-if="modals.details.form.element"
        title="Szczegóły generowania AI"
        title-class="font-18"
        hide-footer
        @hide="hideDetailsModal"
        @esc="hideDetailsModal">

      <template v-if="!modals.details.form.element.aiName.rejected">
        <div class="form-group text-center" v-if="modals.details.form.element.aiName.generated">
          <h2 class="text-danger">Wygenerowana nazwa:</h2>
          <p>{{ modals.details.form.element.aiName.generated.result }}</p>

          <div class="button-items">
            <b-button variant="success" class="btn-lg" @click="openAcceptingModal('NAME', modals.details.form.element.aiName.generated.result)" :disabled="modals.details.form.element.aiName.accepted">{{ modals.details.form.element.aiName.accepted ? "Zaakceptowane" : "Akceptuj" }}</b-button>
            <b-button @click="modals.details.form.element.aiName.rejected = true" variant="danger" class="btn-lg" :disabled="modals.details.form.element.aiName.accepted">Odrzuć</b-button>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="text-center">
          <h2 class="text-danger">Wygenerowana nazwa:</h2>
          <p class="text-warning">Nazwa została odrzucona.</p>
        </div>
      </template>

      <template v-if="!modals.details.form.element.aiDescription.rejected">
        <div class="form-group" v-if="modals.details.form.element.aiDescription.generated">
          <h2 class="text-danger text-center">Wygenerowany opis:</h2>
          <div v-html="modals.details.form.element.aiDescription.generated.result"></div>

          <div class="text-center button-items">
            <b-button variant="success" class="btn-lg" @click="openAcceptingModal('DESCRIPTION', modals.details.form.element.aiDescription.generated.result)" :disabled="modals.details.form.element.aiDescription.accepted">{{ modals.details.form.element.aiDescription.accepted ? "Zaakceptowane" : "Akceptuj" }}</b-button>
            <b-button @click="modals.details.form.element.aiDescription.rejected = true" variant="danger" class="btn-lg" :disabled="modals.details.form.element.aiDescription.accepted">Odrzuć</b-button>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="text-center">
          <h2 class="text-danger">Wygenerowany opis:</h2>
          <p class="text-warning">Opis został odrzucony.</p>
        </div>
      </template>
    </b-modal>

    <b-modal
        id="modal-2"
        v-model="modals.accepting.visible"
        :title="modals.accepting.form.type === 'DESCRIPTION' ? 'Akceptowanie opisu' : 'Akceptowanie nazwy'"
        title-class="font-18"
        hide-footer
        @hide="hideAcceptingModal"
        @esc="hideAcceptingModal">

      <div class="form-group">
        <ecat-multiselect
              @change="value => modals.accepting.form.shopId = value"
              save-id="id"
              view-id="name"
              param="name"
              label="Sklep"
              reset-option-name="Wszystkie sklepy"
              placeholder="Wybierz sklep"
              :load-url="`/shop/${user.id}/list/pagination`"
              :query-url="`/shop/${user.id}/by-name`"
              :show-labels="false"
              :can-unselect="true"
          />

        <p class="py-2 text-center text-warning" v-if="!modals.accepting.form.shopId">Żaden sklep nie został ustawiony więc zmiana zostanie zaakceptowana dla wszystkich Twoich sklepów.</p>
      </div>

      <hr />

      <div class="text-center">
        <b-button variant="success" @click="acceptGenerated">Akceptuj</b-button>
        <b-button class="ml-1" variant="danger" @click="hideAcceptingModal">{{  $t('message.cancel') }}</b-button>
      </div>

    </b-modal>

  </Layout>
</template>